html{
    background:$white;
    width:100vw;
    overflow-x: hidden;
  }
  
  body {
    font-size: 0.875rem;
    color: $black;
    font-family: 'Roboto', arial, sans-serif;
    line-height: 1.5;
    h1, .h1 {
      line-height: 3rem;
    }
    h1, .h1 {
      font-size: 2.5rem;
    }
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  
      margin-bottom: 1.5rem;
      font-family: 'Roboto', arial, sans-serif;;
      color: $black;
    }
    a {
      color: $secondary;
      text-decoration: none !important;
      &:hover {
        text-decoration: none !important;
        color: #0747A6;
      }
    }
    background-repeat:no-repeat;
    background-size:cover;
    position:relative;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .pageTopTile{
    font-size:18px;
    line-height: 40px;
  }
  .pageTopcustomers{
    font-size:12px;
    line-height: 40px;
    float:right;
  }
  .loading{
    text-align: center;
    width:100%;
   
    svg{
      font-size:50px;
    }
    span{
      font-size:20px;
    }
    .icon {
      font-size:50px;
    }
    &.extraspace{
      margin:100px 0;
    }
  }
  .noContent{
    text-align: center;
    width:100%;
    color:$grey;
    svg{
      font-size:90px;
    }
    span{
      font-size:20px;
    }
    .icon {
      font-size:90px;
    }
  }
  .loader {
    display:inline-block;
    border: 16px solid $grey;
    border-radius: 50%;
    border-top: 16px solid $secondary;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  .redBG{
    background:red;
    color: white;
  }
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  #root{
  
  }
  .tab-content{
    background:$white;
  }
  .no_stuff{
    margin-top:50px;
    text-align: center;
    h1{
      color:$grey;
    }
    p{
      color: $grey;
    }
    .largeIcon{
      font-size: 50px;
    }
  }
  [role=main] {
    padding: 0px !important;
    /* Space for fixed navbar */
  }
  .badge{
    font-weight: 500!important;
    padding:5px 4px;
    margin:0 7px;
    font-size: 12px;
    &.badge-secondary{
      background:$secondary;
      color:$white;
    }
    &.badge-default{
      padding:3px 4px;
      color:$dark-grey;
      border:2px solid $dark-grey;
    }
    &.badge-primary{
      background:$primary;
      color:$white;
    }
  }
  .height100{
    height:100%;
  }
  .horiScroll{
    overflow-x: auto;
  }
  .cusomerSlide{
    position:absolute;
  }
  .inlineholder{
    padding:6px;
    border:1px solid $grey;
    margin:0 10px 10px 0;
    display:inline-block;
    .error{
      border-color:red;
      color:red;
    }
  }
  .no-margin{
    margin:0px!important;
  }
  


  .folderholder{
    text-align: center;
    .card-header{
      text-align: center;
    }
    .icon{
      font-size: 74px;;
    }
  }

.contentView{
  .card-body{
    padding:10px!important;
  }
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(226, 226, 226)!important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(226, 226, 226)!important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(226, 226, 226)!important;
}