.footer{
    height:42px;
    background:$white;
    .links{
        line-height: 42px;
        padding:0 20px;
    }
    .foot-link{
        padding:0 10px;
        &.border{
            border:none!important;
            border-right:1px solid $black!important;
        }
    }
}