form{
    .btn{
        margin-left: 0px;
    }
}
.input-group-prepend{
    &.error{
        .input-group-text{
            background:red;
            color:white;
        }
    }
}
.formerror{
    border:2px solid red;
}