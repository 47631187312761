.helpoption{
    text-align: center;
    .card-body{
        min-height: 150px;
        position: relative;
        .btn{
            position:absolute;
            bottom:0;
            left:0;
            margin:0 10% 10px;
            width:80%;
        }
    }
    
    &:hover{
        box-shadow: 3px 3px 17px $light-grey;
    }
}
.helpcontent{
    border-top:1px solid $grey;
    border-bottom:1px solid $grey;
    margin:10px 0;
    padding:10px 20px;
    height:50vh;
    overflow:auto;
}