.accordion{
    .active{
        background:$secondary;
        color:$white;
        border-color: $secondary;
        h5{
            font-size:18px;
            color:$white;
            margin-bottom:15px;
        }
    }
    h5{
        font-size:15px;
        margin:0px;
    }
}