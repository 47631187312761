.nomargin{
    margin:0!important;
}
.inner {
    height:calc(100vh - 107px);
    padding:20px 37px 0 0!important;
    overflow-x:hidden;
    overflow:auto;
    width:100%;
    background:$lighter-grey;
    box-sizing: content-box;
    ::-webkit-scrollbar {
        display: none;
    }
}
.hide{
    display:none!important;
}
.mastershow:hover > .hide{
    display: initial!important;
}
.width100{
    display:block;
    width:100%;
}
.textcenter {
    text-align: center;
    display: block;
}
.textleft {
    text-align: left;
    display: block;
}
.textright {
    text-align: right;
    display: block;
}
.noselect{
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}
.click{
    cursor: pointer;
    color:$secondary;
}
.nopadding{
    padding:0;
}
.space{
    &.bottom {
        margin-bottom:10px!important;
    }
    &.top {
        margin-top: 10px!important;
    }
    &.right{
        margin-right: 10px!important;
    }
    &.left{
        margin-left: 10px!important;
    }
}
.morespace{
    &.bottom {
        margin-bottom:20px!important;
    }
    &.top {
        margin-top: 20px!important;
    }
    &.right{
        margin-right: 20px!important;
    }
    &.left{
        margin-left: 20px!important;
    }
}
.defaultCatch{
    overflow:hidden;
    color:$grey;
    margin:50px 0!important;
}
  .floatright {
    display: block;
    float: right;
  }
.hidden{
    display:none!important;
}
.row.no-gutters {
    margin-right:0;
    margin-left:0;
  }
  .row.no-gutters > [class*='col-'] {
    padding-right:5px;
    padding-left:0;
  }
  .row.no-gutters > [class*='col-']:last-child {
    padding-right:0;
    padding-left:0;
  }
  .tox-notification{
      display:none!important;
  }