.heat{
    font-weight:bold;
    font-size:12px;
    &.h0{
        color:$secondary;
    }
    &.h0p{
        background:rgba(220, 0, 77, 0.05);
        color:$secondary;
    }
    &.h10{
        background:rgba(220, 0, 77, 0.1);
        color:$secondary;
    }
    &.h20{
        background:rgba(220, 0, 77, 0.2);
        color:$secondary;
    }
    &.h30{
        background:rgba(220, 0, 77, 0.3);
        color:$secondary;
    }
    &.h40{
        background:rgba(220, 0, 77, 0.4);
        color:$secondary;
    }
    &.h50{
        background:rgba(220, 0, 77, 0.5);
        color:$secondary;
    }
    &.h60{
        background:rgba(220, 0, 77, 0.6);
        color:$white;
    }
    &.h70{
        background:rgba(220, 0, 77, 0.7);
        color:$white;
    }
    &.h80{
        background:rgba(220, 0, 77, 0.8);
        color:$white;
    }
    &.h90{
        background:rgba(220, 0, 77, 0.9);
        color:$white;
    }
    &.h100{
        background:$secondary;
        color:$white;
    }
}
.heatmap{
    td{
        border:4px solid $white;
        text-align: center;
        padding:8px 8px!important;
        &.title{
            padding:2px 2px!important;
        }
        font-size:0.8em;
       height:40px;
       .wrap{
           display:block;
           line-height: 36px;
           background:$white;
           height:100%;
           width:100%;
       }
        background:#f2f2f2;
        vertical-align: middle!important;
    }
}
.heatMapPag{
    background:#ddd;
    padding:5px 0;
    .icon{
        margin:0 15px;
        font-size: 10px;
        cursor: pointer;
    }
}