  .filter{
    border-bottom:1px $light-grey solid;
    padding:10px 0;
    border-bottom: solid 1px $light-grey;
    background:$white;
    label{
      line-height: 10px;
      font-size:10px;
      font-weight: bold;
      padding:0px;
      margin:0px;
    }
    .dropdown{
      .btn-secondary{
        background:$white;
      }
      &:active{
        color:$light-grey;
      }
    }
  }
  .PageTop{
    padding:10px 0;
    margin:20px 0 0 0;
    min-height: 46px;
    background:$light-grey;
    .h4{
      line-height:40px!important;
      margin:0;
      font-size: 1.1rem;
      &.up{
        line-height: 20px !important;
        
      }
      font-weight: lighter;
    }
    .breadcrumbs{
      .nav-link{
        display:inline-block;
        padding:0!important;
        margin-right:5px;
        color:$grey;
        &:hover{
          color:$dark-grey;
        }
      }
    }
  }