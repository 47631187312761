.stepindicators{
    .indicatorbuttons{
        .btn{
            margin-top:6px;
        }
    }
    .indicators{
        text-align: center;
        .steps{
            display:inline-block;
            background-color: $light-grey;
            width: 40px;
            padding-top: 40px; /* 1:1 Aspect Ratio */
            position: relative; /* If you want text inside of it */
            border-radius: 50%;
            margin:3px;
            &.complete{
                background-color: $secondary;
                .number {
                    color:$white;
                }
            }
            .number {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                padding:calc(50% - 10px) 0;
                font-size:15px;
                text-align: center;
                font-weight: bold;
            }
        }
    }
    .miniindicators{
        text-align: center;
        .steps{
            display:inline-block;
            background-color: $light-grey;
            width: 10px;
            padding-top: 10px; /* 1:1 Aspect Ratio */
            border-radius: 50%;
            margin:3px;
            &.complete{
                background-color: $secondary;
                .number {
                    color:$white;
                }
            }
        }
    }
}
