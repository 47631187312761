.buttonholder{
    height:44px;
    border-bottom:1px solid $light-grey;
    margin-bottom:10px;
    padding:5px 10px;
  }
.btn {
    font-size: 11px !important;
    padding: 5px 7px;
    border-radius: 3px !important;
    font-weight: 400;
    &.bt-tny{
        line-height: 12px!important;
        font-size: 12px!important;
    }
    &.disabled{
        background:$light-grey!important;
        color:$grey!important;
        border:$grey 1px solid!important;
        cursor: not-allowed;
    }
    &.btn-primary {
        background:$secondary;
        background-size: 400% 400%;
        color: $light-grey;
    }
    &.btn-success {
        background:$secondary;
        border-color:$secondary;
        color: $white;
        &:hover {
            background: $primaryAction;
            border-color:$primaryAction;
            color: $white;
        }
        &:active {
            background: $primaryAction;
            border-color:$primaryAction;
            color: $white;
        }
    }
    &.btn-clear{
        background:transparent;
        color:$black;
        border:none;
        &:hover{
          color:$grey;
        }
        &:focus{
            box-shadow:none;
        }
    }
    
    &.boxbutton{
        border: 2px $secondary solid;
        padding:0 5px;
        margin: 0px 5px;
        color:$secondary;
        background:$white;
        &.active{
            background:$secondary;
            border: 2px $secondary solid;
            color:$white;
            &:hover{
                background:$primaryAction;
                border: 2px $primaryAction solid;
            }
        }
        &:hover{
            background:$primaryAction;
            border: 2px $primaryAction solid;
            color:$white;
        }
        &.warning{
            background:$warning!important;
            border-color:$warning!important;
            color:$white!important;
            &:hover{
                background:$dark-grey!important;
                border-color:$dark-grey!important;
                color:$white!important;
            }
        }
        &.inactive{
            background:$dark-grey;
            border-color: $dark-grey;
            color:$white;
        }
        &.smaller{
            font-size: 10px!important;
            line-height: 10px;
            padding:2px 3px;
            margin:0 2px;
            border-width: 1px;
            &:hover{
                background:$secondary;
                color:$white;
                border-color:$secondary;
            }
        }
        label{
            margin:0!important;
        }
    }
    &.topboxbutton{
        border: 2px $secondary solid;
        padding:0 5px;
        margin: 0px 5px;
        color:$secondary;
        background:$white;
        &.active{
            background:$secondary;
            border: 2px $secondary solid;
            color:$white;
            &:hover{
                background:$primaryAction;
                border: 2px $primaryAction solid;
            }
        }
        &:hover{
            background:$primaryAction;
            border: 2px $primaryAction solid;
            color:$white;
        }
        &.warning{
            background:$warning!important;
            border-color:$warning!important;
            color:$white!important;
            &:hover{
                background:$dark-grey!important;
                border-color:$dark-grey!important;
                color:$white!important;
            }
        }
        &.inactive{
            background:$dark-grey;
            border-color: $dark-grey;
            color:$white;
        }
        &.smaller{
            font-size: 10px!important;
            line-height: 10px;
            padding:2px 3px;
            margin:0 2px;
            border-width: 1px;
            &:hover{
                background:$secondary;
                color:$white;
                border-color:$secondary;
            }
        }
        label{
            margin:0!important;
        }
    }
}
.nobutton{
    background:none;
    border:none;
    cursor:pointer;
}
.nav-pills{
    cursor: pointer;
    .nav-link{
        &.active{
            background:$secondary;
            &:hover{
                color:$white!important;
                
            }
        }
    }
}
.containertabs{
    padding:0 15px;
    margin-bottom:20px!important;
}