$black:#3a3a3a;
$primary:#361c2c;
$primaryAction:#079ba6;
$secondary:#584085;
$secondaryAction:#079ba6;
$tertiary:#079ba6;
$white:#fff;
$light-grey:#e2e2e2;
$grey:#AFB0B0;
$dark-grey:#8D8F8F;
$lighter-grey:#F3F3F3;
$background:none;
$warning:#D6381E;
@import 'custom', 'helpers', 'filters', 'navbar', 'sidebar', 'login', 'bootstrap-overwrites', 'buttons', 'modal', 'charts', 'forms', 'tables', 'news', 'footer', 'help', 'accordion', 'steps', 'heatmap', 'users', 'cards', 'products';