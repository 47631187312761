.singlestats{
    .iconholder{
        text-align: center;
        .icon{
            font-size:50px!important;
            line-height: 130px;
            color:$secondary;
        }
    }
    .text{
        padding:30px 10px;
        .title{
            display:block;
            font-size: 18px;
            color:$dark-grey;
        }
        .stat{
            display:block;
            color:$grey;
        }
        .date{
            display:block;
            color:$light-grey;
        }
    }
}